import axios from 'axios';
import { RegistrationRequest } from './model/registrationRequest.model';
import { RegistrationResponse } from './model/registrationResponse.model';

export const registerContactForm = async (payload: RegistrationRequest) => {
  try {
    const url = `https://goworq-api-986f7d5329b3.herokuapp.com/api/form`;

    const response = await axios.post<RegistrationResponse>(url, payload);

    return response.status;
  } catch (error) {
    console.error('Error fetching cities:', error);
    return [];
  }
};
