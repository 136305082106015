import { TextField, Typography } from '@mui/material';
import colors from '../../utils/theme/colors';
import { styles } from './input.style';
import { useState } from 'react';

interface IInputProps {
  label: string;
  value: string;
  type?: string;
  onChange: (value: string) => void;
  onError?: (error: boolean) => void;
  variant?: 'standard' | 'outlined' | 'filled';
  style?: React.CSSProperties;
}

const Input = ({
  label,
  value,
  type,
  onChange,
  onError,
  variant,
  style,
}: IInputProps) => {
  const prefix = '+56 ';
  const maxLength = 9;
  const [error, setError ] = useState<string | null>(null)

  const handleChange = (value: string) => {
    let newError: string | null = null;
    if (type === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        newError ='Por favor, ingresa un correo electrónico válido.';

      } else {
        setError(null);
      }
    }

    if (type === 'phone') {
      if (!value.startsWith(prefix)) {
        value = prefix;
      }

      let phoneNumber = value.replace(prefix, '');

      if (!/^\d*$/.test(phoneNumber)) {
        return;
      }

      if (phoneNumber.length > maxLength) {
        phoneNumber = phoneNumber.slice(0, maxLength);
      }

      if (phoneNumber.length < maxLength) {
        newError = 'El número ingresado debe contener 9 dígitos.';
      } else {
        newError = null;
      }

      value = prefix + phoneNumber
    }

    setError(newError);
    onError && onError(!!newError);
    onChange(value);
  };

  return (
    <TextField
      fullWidth
      label={<Typography style={{fontFamily: 'poppins'}}>{label}</Typography>}
      variant={variant ?? 'standard'}
      value={value}
      onChange={(event) => handleChange(event.target.value)}
      error={!!error}
      helperText={error}
      type={type ?? 'text'}
      style={style ?? styles.input}
      InputProps={{
        style: styles.inputProps,
        disableUnderline: false,
        classes: {
          underline: 'custom-underline',
        },
      }}
      InputLabelProps={{
        style: styles.inputProps,
      }}
      sx={{
        '& .MuiInput-underline:before': {
          borderBottomColor: colors.primary,
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: colors.primary,
        },
      }}
    />
  );
};

export default Input;
