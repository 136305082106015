import { useMediaQuery } from 'react-responsive';

const useScreen = () => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 840px)' });

  return {
    isSmallScreen,
  };
};

export default useScreen;
