import axios from 'axios';
import { SkillModel } from './model/skill.model';
import { mapSkillsToTags } from './mapper/skills.mapper';

export const getSkills = async () => {
  try {
    const url =
      'https://goworq-api-986f7d5329b3.herokuapp.com/api/skills';

    const response = await axios.get<SkillModel[]>(url);

    return mapSkillsToTags(response.data);
  } catch (error) {
    console.error('Error fetching skills:', error);
    return [];
  }
};
