import { ButtonGroup } from '@mui/material';
import {
  BUTTON_EMPLOYER,
  BUTTON_WORKER,
  LABEL_SUBTITLE,
} from './constants/text.constants';
import { StyledButtonLeft, StyledButtonRight, styles } from './home.style';
import ModalRegistrationForm from '../../components/modal/modalRegistrationForm/ModalRegistrationForm';
import { RegistrationFormType } from '../../utils/enum/registrationFormType.enum';
import useHomeScreen from '../../hooks/useHomeScreen/useHomeScreen';
import { isAndroid, isMobile } from 'react-device-detect';

const HomeScreen = () => {
  const { isSmallScreen, openModal, formType, setOpenModal, handleOpenForm } =
    useHomeScreen();

  return (
    <div>
      <div style={styles.imageBackground}>
        <div style={styles.container}>
          <div style={styles.titleContainer}>
            <p
              style={{
                ...styles.title,
                lineHeight: isMobile ? '40px' : isSmallScreen ? '80px' : '4px',
                fontSize: isMobile ? '40px' : '80px',
              }}
            >
              Únete a la fuerza de{' '}
              <p
                style={{
                  ...styles.title2,
                  fontSize: isMobile ? '45px' : '96px',
                }}
              >
                GoworQ
              </p>
            </p>
          </div>

          <div style={styles.subtitleContainer}>
            <div style={styles.subtitleBackground}>
              <h2
                style={{
                  ...styles.subtitleText,
                  fontSize: isMobile ? '14px' : '20px',
                  paddingTop: isAndroid ? '24px' : '0px',
                  paddingLeft: isMobile ? '14px' : '0px',
                  paddingRight: isMobile ? '14px' : '0px',
                }}
              >
                {LABEL_SUBTITLE}
              </h2>
            </div>
          </div>

          <div style={styles.buttonsContainer}>
            <ButtonGroup
              orientation={isSmallScreen ? 'vertical' : 'horizontal'}
              aria-label="Basic button group"
            >
              <StyledButtonLeft
                onClick={() => handleOpenForm(RegistrationFormType.WORKER)}
              >
                {BUTTON_WORKER}
              </StyledButtonLeft>
              <StyledButtonRight
                onClick={() => handleOpenForm(RegistrationFormType.EMPLOYER)}
              >
                {BUTTON_EMPLOYER}
              </StyledButtonRight>
            </ButtonGroup>
          </div>
        </div>
      </div>
      <ModalRegistrationForm
        open={openModal}
        setOpen={setOpenModal}
        formType={formType}
      />
    </div>
  );
};

export default HomeScreen;
