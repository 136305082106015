import ImageBackground from '../../assets/img/home-bg.png';
import colors from '../../utils/theme/colors';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { isMobile } from 'react-device-detect';


type Style = {
  container: React.CSSProperties;
  imageBackground: React.CSSProperties;
  titleContainer: React.CSSProperties;
  title: React.CSSProperties;
  title2: React.CSSProperties;
  subtitleContainer: React.CSSProperties;
  subtitleBackground: React.CSSProperties;
  subtitleText: React.CSSProperties;
  buttonsContainer: React.CSSProperties;
};

export const StyledButtonLeft = styled(Button)`
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  width: ${isMobile ? '300' : '412px'};
  height: 100px;
  color: ${colors.primary} !important;
  background-color: ${colors.white} !important;
  font-size: ${isMobile ? '20px' : '28px'} !important;

  @media (max-width: 840px) {
    border-top-right-radius: 50px !important;
    border-bottom-left-radius: 0px !important;
  }
`;

export const StyledButtonRight = styled(Button)`
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  width: ${isMobile ? '300' : '412px'};
  height: 100px;
  color: ${colors.primary} !important;
  background-color: ${colors.white} !important;
  font-size: ${isMobile ? '20px' : '28px'} !important;

  @media (max-width: 840px) {
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 50px !important;
  }
`;

export const styles: Style = {
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    zIndex: 20,
  },
  imageBackground: {
    display: 'flex',
    backgroundImage: `url(${ImageBackground})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '80vh',
    marginBottom: '-30px',
    paddingBottom: isMobile ? '100px' :'0px'
  },
  titleContainer: {
    flex: 1,
    alignContent: 'flex-end',
  },
  title: {
    lineHeight: '4px',
    color: colors.white,
    fontWeight: 'normal',
    fontSize: '80px'
  },
  title2: {
    color: colors.white,
    fontWeight: 'bold',
    fontSize: '96px',
  },
  subtitleContainer: {
    flex: 0.5,
    alignContent: 'center',
    textAlign: 'center',
    verticalAlign: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%',
  },
  subtitleBackground: {
    backgroundColor: `rgba(255, 255, 255, 0.15)`,
    height: '100px',
    width: '100%',
    alignContent: 'center',
  },
  subtitleText: {
    color: colors.white,
    fontWeight: 'normal',
  },
  buttonsContainer: {
    flex: 0.5,
    alignItems: 'center',
  },
};


