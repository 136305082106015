import { Autocomplete, Chip, TextField, Typography } from "@mui/material";
import { styles } from "./tagInput.style";
import colors from "../../utils/theme/colors";
import { ITags } from "../../utils/model/TagInput/ITags.model";

interface ITagInputProps {
    label: string;
    placeholder?: string;
    tags: ITags[];
    setTags: (tags: ITags[]) => void;
    options: ITags[];
    supportText?: string;
}

const TagInput = ({label, placeholder, tags, setTags, options, supportText}: ITagInputProps) => {
    return (
      <Autocomplete
        multiple
        id="tags-outlined"
        options={options}
        getOptionLabel={(option) => option.name}
        value={tags}
        style={styles.tagInput}
        onChange={(event, newValue) => {
          if (newValue.length <= 5) {
            setTags(newValue);
          }
        }}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={<Typography style={{fontFamily: 'poppins'}}>{label}</Typography>}
            placeholder={placeholder ?? ''}
            helperText={<Typography style={{fontFamily: 'poppins', fontSize: '14px', color: colors.primary}}>{supportText}</Typography>}
            InputProps={{
                ...params.InputProps,
                style: styles.tagInputProps,
                disableUnderline: false,
                classes: {
                  underline: 'custom-underline',
                },
              }}
              InputLabelProps={{
                ...params.InputLabelProps,
                style: styles.tagInputProps,
              }}
              sx={{
                '& .MuiInput-underline:before': {
                  borderBottomColor: colors.primary,
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: colors.primary,
                },
              }}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={<Typography style={{fontFamily: 'poppins'}}>{option.name}</Typography>}
              style={styles.chip}
              {...getTagProps({ index })}
            />
          ))
        }
        renderOption={(props, option) => (
          <li {...props}>
            <Typography style={{ fontFamily: 'poppins', color: colors.primary }}>
              {option.name}
            </Typography>
          </li>
        )}
      />
    );
  };

  export default TagInput