import { ITags } from '../../../utils/model/TagInput/ITags.model';
import { SkillModel } from '../model/skill.model';

export const mapSkillsToTags = (data: SkillModel[]) => {
  const skills: ITags[] = [];
  data?.map((skill: SkillModel) => {
    const skillTag: ITags = {
      id: skill.id.toString(),
      name: skill.nombre,
    };
    return skills.push(skillTag);
  });

  return skills;
};
