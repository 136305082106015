import { isMobile } from "react-device-detect";
import colors from "../../utils/theme/colors";

type Style = {
  tagInput: React.CSSProperties;
  tagInputProps: React.CSSProperties;
  chip: React.CSSProperties;
};

export const styles: Style = {
  tagInput: {
    width: isMobile ? '250px' : '300px',
    alignSelf: isMobile ? 'center' : '',
    marginBottom: '75px',
  },
  tagInputProps: {
    color: colors.primary,
  },
  chip: {
    color: colors.primary,
  }
};
