import { useState } from 'react';
import useScreen from '../useScreen/useScreen';
import { RegistrationFormType } from '../../utils/enum/registrationFormType.enum';

const useHomeScreen = () => {
  const { isSmallScreen } = useScreen();
  const [openModal, setOpenModal] = useState(false);
  const [formType, setFormType] = useState<RegistrationFormType>(
    RegistrationFormType.WORKER
  );

  const handleOpenForm = (type: RegistrationFormType) => {
    setFormType(type);
    setOpenModal(true);
  };

  return {
    isSmallScreen,
    openModal,
    formType,
    setOpenModal,
    handleOpenForm,
  };
};

export default useHomeScreen;
