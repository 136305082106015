import { styled } from '@mui/material/styles';
import colors from '../../utils/theme/colors';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { isMobile } from 'react-device-detect';

type Style = {
  container: React.CSSProperties;
  topHalfContainer: React.CSSProperties;
  bottomHalfContainer: React.CSSProperties;
  copyrightText: React.CSSProperties;
  descriptionContainer: React.CSSProperties;
  descriptionText: React.CSSProperties;
  logoContainer: React.CSSProperties;
  linksContainer: React.CSSProperties;
  linksText: React.CSSProperties;
  termsAndConditionsContainer: React.CSSProperties;
};

export const styles: Style = {
  container: {
    display: 'flex',
    bottom: 0,
    flexDirection: 'column',
    backgroundColor: colors.primary,
    width: '100%',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    zIndex: 10
  },
  topHalfContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  bottomHalfContainer: {
    display: 'flex',
    flex: 0.25,
    flexDirection: 'row',
    justifyContent: 'center',
    borderTop: '1px solid white',
    marginLeft: '45px',
    marginRight: '45px',
  },
  copyrightText: {
    color: colors.white,
    fontSize: '16px',
    fontWeight: 'normal',
  },
  descriptionContainer: {
    flex: 1,
    paddingLeft: isMobile ? '10px' : '45px',
    paddingTop: isMobile ? '10px' : '45px',
  },
  descriptionText: {
    color: colors.white,
    fontSize: '16px',
    fontWeight: 'normal',
    textAlign: isMobile ? 'center' : 'left'
  },
  logoContainer: {
    flex: 0.75,
    paddingTop: '45px',
  },
  linksContainer: {
    flex: 1,
    paddingRight: '45px',
    paddingTop: '45px',
  },
  linksText: {
    color: colors.white,
    fontSize: '16px',
    fontWeight: 'normal',
    textAlign: 'right'
  },
  termsAndConditionsContainer: {
    textAlign: 'center',
    fontFamily: 'poppins',
  }
};

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: colors.primary,
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));