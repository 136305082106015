import './App.css';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import HomeScreen from './screens/home/HomeScreen';

function App() {
  return (
    <div className="App">
          <Header />
      <div
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div style={{ flex: 1 }}>
          <HomeScreen />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
