import { IOptions } from '../../../utils/model/SelectInput/IOptions.model';
import { RegionModel } from '../model/region.model';

export const mapRegionsToOptions = (data: RegionModel[]) => {
  const regions: IOptions[] = [];
  data?.map((region: RegionModel) => {
    const regionOption: IOptions = {
      label: region.nombre,
      value: region.id.toString(),
    };
    return regions.push(regionOption);
  });

  return regions;
};
