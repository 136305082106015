import {
  FOOTER_DESCRIPTION,
  LABEL_ABOUT,
  LABEL_COPYRIGHT,
  LABEL_TERMS_AND_CONDITIONS,
} from './constants/text.constants';
import { HtmlTooltip, styles } from './footer.style';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import Typography from '@mui/material/Typography';
import { TERMS_AND_CONDITIONS } from '../../utils/constants/termsAndConditions.constants';
import { ABOUT } from '../../utils/constants/about.constants';
import useScreen from '../../hooks/useScreen/useScreen';

const Footer = () => {
  const { isSmallScreen } = useScreen();
  return (
    <div style={{
      ...styles.container,
      height: isSmallScreen ? '100%' : '256px',
    }}>
      <div style={{...styles.topHalfContainer, flexDirection: isSmallScreen ? 'column' : 'row'}}>
        <div style={styles.descriptionContainer}>
          <p style={styles.descriptionText}>{FOOTER_DESCRIPTION}</p>
        </div>
        <div style={styles.logoContainer}>
          <Logo />
        </div>
        <div style={{...styles.linksContainer, paddingRight: isSmallScreen ? '0px' : '45px',}}>
          <HtmlTooltip
            title={
              <div style={styles.termsAndConditionsContainer}>
                <Typography
                  color="inherit"
                  fontWeight="bold"
                  fontFamily="poppins"
                >
                  Términos y Condiciones de Uso de GoworQ
                </Typography>
                <p>{TERMS_AND_CONDITIONS}</p>
              </div>
            }
          >
            <p style={{...styles.linksText, textAlign: isSmallScreen ? 'center' : 'right' }}>{LABEL_TERMS_AND_CONDITIONS}</p>
          </HtmlTooltip>
          <HtmlTooltip
            title={
              <div style={styles.termsAndConditionsContainer}>
                <Typography
                  color="inherit"
                  fontWeight="bold"
                  fontFamily="poppins"
                >
                  Acerca de
                </Typography>
                <p>{ABOUT}</p>
              </div>
            }
          >
             <p style={{...styles.linksText, textAlign: isSmallScreen ? 'center' : 'right' }}>{LABEL_ABOUT}</p>
          </HtmlTooltip>

         
        </div>
      </div>
      <div style={styles.bottomHalfContainer}>
        <p style={styles.copyrightText}>{LABEL_COPYRIGHT}</p>
      </div>
    </div>
  );
};

export default Footer;
