import { isMobile } from 'react-device-detect';
import colors from '../../utils/theme/colors';

type Style = {
  input: React.CSSProperties;
  inputProps: React.CSSProperties;
};

export const styles: Style = {
  input: {
    width: isMobile ? '250px' : '300px',
    alignSelf: isMobile ? 'center' : '',
    marginBottom: '75px',
  },
  inputProps: {
    fontFamily: 'poppins',
    color: colors.primary,
  },
};
