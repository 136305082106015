import { isMobile } from "react-device-detect";
import colors from "../../utils/theme/colors";

type Style = {
  selectInput: React.CSSProperties;
  menuItem: React.CSSProperties;
};

export const styles: Style = {
  selectInput: {
    width: isMobile ? '250px' : '300px',
    alignSelf: isMobile ? 'center' : '',
    marginBottom: '75px',
  },
  menuItem: {
    color: colors.primary
  }
};
