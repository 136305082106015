import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { styles } from './selectInput.style';
import colors from '../../utils/theme/colors';
import { IOptions } from '../../utils/model/SelectInput/IOptions.model';

interface ISelectInputProps {
  label: string;
  value: string | null;
  onChange: (value: string) => void;
  options: IOptions[];
}

const SelectInput = ({
  label,
  value,
  onChange,
  options,
}: ISelectInputProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  return (
    <FormControl variant="standard" style={styles.selectInput}>
      <InputLabel
        id="demo-simple-select-standard-label"
        style={{ color: colors.primary }}
      >
        {<Typography style={{fontFamily: 'poppins'}}>{label}</Typography>}
      </InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={value?.toString()}
        onChange={handleChange}
        label={<Typography style={{fontFamily: 'poppins'}}>{label}</Typography>}
        sx={{
          color: colors.primary,
          ':before': { borderBottomColor: 'purple' },
          ':after': { borderBottomColor: 'purple' },
        }}
      >
        {options?.map((option, index) => (
          <MenuItem
            key={index+1}
            value={option.value}
            style={styles.menuItem}
          >
            {<Typography style={{fontFamily: 'poppins'}}>{option.label}</Typography>}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
