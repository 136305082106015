import { IOptions } from '../../../utils/model/SelectInput/IOptions.model';
import { CityModel } from '../model/city.model';

export const mapCitiesToOptions = (data: CityModel[]) => {
  const cities: IOptions[] = [];
  data?.map((city: CityModel) => {
    const cityOption: IOptions = {
      label: city.nombre,
      value: city.id.toString()
    };
    return cities.push(cityOption);
  });

  return cities;
};
