import { styles } from './header.style';
import { ReactComponent as LogoLabel } from '../../assets/icons/logo-label.svg';
import { ReactComponent as LogoBG } from '../../assets/icons/logo-bg.svg';
import { isMobile } from 'react-device-detect';

const Header = () => {
  return (
    <div style={styles.container}>
      {!isMobile && (
        <div style={styles.logoContainer}>
          <LogoBG />
        </div>
      )}

      <div style={styles.logoLabelContainer}>
        <LogoLabel />
      </div>
    </div>
  );
};

export default Header;
