import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Snackbar,
  Backdrop,
  CircularProgress,
  Typography,
} from '@mui/material';
import Input from '../../input/Input';
import {
  LABEL_BUTTON,
  LABEL_CITY,
  LABEL_EMAIL,
  LABEL_NAME,
  LABEL_PHONE,
  LABEL_PROFESSION,
  LABEL_PROFESSION_MOBILE,
  LABEL_REGION,
  TITLE_EMPLOYER,
  TITLE_WORKER,
} from './constants/text.constants';
import SelectInput from '../../selectInput/SelectInput';
import TagInput from '../../tagInput/TagInput';
import colors from '../../../utils/theme/colors';
import { HtmlTooltip, styles } from './modalRegistrationForm.style';
import useModalRegistrationForm from '../../../hooks/useModalRegistrationForm/useModalRegistrationForm';
import { RegistrationFormType } from '../../../utils/enum/registrationFormType.enum';
import React from 'react';
import { TERMS_AND_CONDITIONS } from '../../../utils/constants/termsAndConditions.constants';
import { isMobile } from 'react-device-detect';

interface ModalRegistrationFormProps {
  open: boolean;
  setOpen(value: boolean): void;
  formType: RegistrationFormType;
}

const ModalRegistrationForm = ({
  open,
  setOpen,
  formType,
}: ModalRegistrationFormProps) => {
  const {
    isSmallScreen,
    isLoading,
    name,
    phone,
    email,
    region,
    city,
    isChecked,
    skills,
    regions,
    cities,
    skillsOptions,
    isButtonDisabled,
    openSnack,
    snackAction,
    setName,
    setPhone,
    setEmail,
    setRegion,
    setCity,
    setIsChecked,
    setSkills,
    setPhoneError,
    setEmailError,
    handleSubmit,
    handleCloseModal,
    handleCloseSnack,
  } = useModalRegistrationForm({ setOpen });

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnack}
        autoHideDuration={4000}
        onClose={handleCloseSnack}
        action={snackAction}
      >
        <Alert
          onClose={handleCloseSnack}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Registro creado con éxito
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '25px',
            padding: isMobile ? '20px' : '48px',
          },
        }}
      >
        <DialogTitle style={styles.title}>
          {formType === RegistrationFormType.WORKER
            ? TITLE_WORKER
            : TITLE_EMPLOYER}
        </DialogTitle>
        <DialogContent>
          <hr style={{ marginBottom: '58px' }} />
          <Box
            display="flex"
            flexDirection={isSmallScreen ? 'column' : 'row'}
            flexWrap="wrap"
            justifyContent="space-around"
            alignContent="center"
          >
            <Input label={LABEL_NAME} value={name} onChange={setName} />
            <Input
              label={LABEL_PHONE}
              value={phone}
              onChange={setPhone}
              onError={setPhoneError}
              type="phone"
            />
            <Input
              label={LABEL_EMAIL}
              value={email}
              onChange={setEmail}
              onError={setEmailError}
              type={'email'}
            />
            <SelectInput
              label={LABEL_REGION}
              value={region}
              onChange={setRegion}
              options={regions}
            />
            <SelectInput
              label={LABEL_CITY}
              value={city}
              onChange={setCity}
              options={cities}
            />
            <TagInput
              label={isMobile ? LABEL_PROFESSION_MOBILE : LABEL_PROFESSION}
              tags={skills}
              setTags={setSkills}
              options={skillsOptions}
              supportText='(Máx. 5)'
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: '20px' }}
          >
            <div style={{ marginBottom: '24px' }}>
              <HtmlTooltip
                title={
                  <div style={styles.termsAndConditionsContainer}>
                    <Typography color="inherit" fontWeight='bold' fontFamily='poppins'>
                      Términos y Condiciones de Uso de GoworQ
                    </Typography>
                    <p>{TERMS_AND_CONDITIONS}</p>
                  </div>
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={(event) => setIsChecked(event.target.checked)}
                      style={{ color: colors.primary }}
                    />
                  }
                  label={<p style={{fontFamily: 'poppins'}}>He leído y acepto los términos y condiciones {isMobile && '(mantener para leer)'}</p>}
                  style={{ color: colors.primary, textAlign: isMobile ? 'center' : 'left' }}
                />
              </HtmlTooltip>
            </div>
            <Button
              variant="contained"
              color="primary"
              disabled={isButtonDisabled}
              onClick={() => handleSubmit(formType)}
              sx={{
                backgroundColor: isButtonDisabled ? 'grey' : colors.primary,
                height: isMobile ? '40px' : '80px',
                width: isMobile ? '200px' :'400px',
                borderRadius: '100px',
                fontFamily: 'Poppins',
                fontSize: isMobile ? '16px' : '28px',
                '&.Mui-disabled': {
                  backgroundColor: 'grey',
                  color: 'white',
                },
                '&:hover': {
                  backgroundColor: isButtonDisabled ? 'grey' : colors.secondary,
                },
              }}
            >
              {LABEL_BUTTON}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Backdrop sx={{ color: '#fff', zIndex: 100 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ModalRegistrationForm;
