import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import colors from '../../../utils/theme/colors';
import { styled } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';

type Style = {
  title: React.CSSProperties;
  termsAndConditionsContainer: React.CSSProperties;
};

export const styles: Style = {
  title: {
    fontFamily: 'poppins',
    fontSize: isMobile ? '20px' : '40px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: colors.primary,
  },
  termsAndConditionsContainer: {
    textAlign: 'center',
    fontFamily: 'poppins',
  }
};

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: colors.primary,
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));
